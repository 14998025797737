#banner-upstream {
    .banner-area {
        background: rgba(0, 0, 0, 0.12);
        border-radius: 32px 32px 0 0;
        height: 60px;
        width: 100%;
        // position: fixed;
        // bottom: 0px;
    }

    .flex-container {
        padding-right: 26px;
        padding-left: 26px;
        gap: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      max-width: 325px;
      margin-left: auto;
      margin-right: auto;
    }

    img {
        height: 22px;
    }

    .service-text {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        color: #FFFFFF;
    }
}
