@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  height: 100%;
}

body {
  font-family: 'Nunito', sans-serif;
  color: #303030;
  width: 100%;
  height: 100%;

  * {
    font-family: 'Nunito', sans-serif;
  }
}

#root {
  height: 100%;
  position: relative;
  overflow: hidden;
}

p {
  margin: 0;
}

.content {
  max-width: 40em;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  //height: 100%;
}

.form-control {
  background: #E9EBF0;
  border: 2px solid #545454;
  box-shadow: inset 0 6px 6px rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  padding: 12px 16px;
  gap: 10px;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8em;
}

input.justify-content {
  font-size: 1.25em;
  text-align: center;
}

:root {
  --swiper-theme-color: #fff;
}

.header-transparent {
  display: flex;
  justify-content: center;

  .logo {
    height: 52px;
    margin-top: 29px;
  }

  @media screen and (max-width: 360px),
  screen and (max-height: 680px) {
    .logo {
      height: 30px;
      margin-top: 12px;
    }
  }
}

.swiper {
  &.swiper-horizontal > .swiper-pagination-bullets {
    position: initial;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .swiper-pagination-bullet {
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.4);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    opacity: 1;
    transition: all .3s ease-in-out;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
    width: 16px;
    height: 10px;
    border-radius: 100px;
  }
}

.start-tutorial-icon {
  width: 34px;
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes floating {
  0% {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.blur {
  filter: blur(5px);
}
