@import "../../../mixins";

.button {
  width: 100%;
  height: 55px;
  border-radius: 16px;
  padding-right: 1px;
  padding-left: 1px;
  padding-top: 0;
  font-family: 'Nunito', sans-serif;
  //margin-top: 10px;
  position: relative;
  filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.35));

  .bg-to-animate {
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border-radius: 16px;
    opacity: 0;
  }

  span {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 33px;
    top: 8px;
    position: relative;
    color: #FFFFFF;
    @include shadow-text;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3em;

    &.small {
      font-size: 17px;
    }
  }

  &.button-primary {
    background: linear-gradient(180deg, #FDD70E 0%, #FD9D0E 100%);
    border: 2px solid #c56b09;
    box-shadow: inset 0px -6px 0px #EE7A0D;
  }

  &.button-success,
  .button-success {
    background: linear-gradient(184.36deg, #56D598 3.53%, #0E884E 96.46%);
    border: 2px solid #135736;
    box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
  }

  &.button-red {
    background: red;
  }

  &.button-gold,
  .button-gold {
    background: linear-gradient(119.39deg, #D96D00 9.52%, #FFCC00 58.66%, #ED9500 82.1%);
    border: 2px solid #AC4E4E;
    box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
    height: 60px;

    .button-inner-overlay {
      background: transparent;
    }

    span {
      font-weight: 900;
      font-size: 32px;
      line-height: 27px;
    }
  }

  &.button-ladder-yellow {
    background: #FFE1BF;
  }

  &.button-white {
    background: #F4F4F4;
    border: 2px solid #9F9F9F;
    box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);

    .button-inner-overlay {
      background: #fff;
      width: 97.4%;
    }

    span {
      color: #303030;
      text-shadow: none;
      filter: none;
    }
  }

  &.button-ladder-white {
    background: #F4F4F4;
    border: 2px solid #9F9F9F;
    box-shadow: inset 0px -6px 0px rgba(0, 0, 0, 0.25);
    font-size: 22px;
    margin-top: 0px;

    span {
      color: #303030;
      text-shadow: none;
    }
  }

  &.button-transparent {
    background: rgba(255, 255, 255, 0);
    border: 2px solid rgba(255, 255, 255, 0);
    box-shadow: none;
    filter: none;

    span {
      text-shadow: none;
      filter: none;
      font-weight: 700;
    }

    .inner-overlay {
      background: rgba(255, 255, 255, 0);
    }

    .button-inner-overlay {
      background: transparent;
    }
  }

  &:disabled,
  &.button-disabled-transparent {
    opacity: 0.4;
  }
}

.button-inner-overlay {
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
}
